'use strict';

$(function() {
    $('.top-bar-container').data('size', 'big');
});

/*
$(window).scroll(function() {
    if ($(document).scrollTop() > 0) {
        if ($('.top-bar-container').data('size') === 'big') {
            $('.top-bar-container').data('size', 'small');
            $('.top-bar-container').addClass('small');
        }
    } else {
        if ($('.top-bar-container').data('size') === 'small') {
            $('.top-bar-container').data('size', 'big');
            $('.top-bar-container').removeClass('small');
        }
    }
});
*/

function fixedTopBar() {
    if ($(window).width() >= 768 && !$('body').hasClass('f-topbar-fixed')) {
        $('body').addClass('f-topbar-fixed');
    }

    if ($(window).width() < 768 && $('body').hasClass('f-topbar-fixed')) {
        $('body').removeClass('f-topbar-fixed');
    }
}

$(window).on('resize', function() {
    fixedTopBar();
});

fixedTopBar();
