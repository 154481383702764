'use strict';
$(document).ready(function() {
    $('.taxonomy-list-view .columns .grid-wrap').isotope({
        itemSelector: '.letter-block',
        layoutMode: 'moduloColumns',
        // layoutMode: 'packery',
        isResizeBound: true,
        percentPosition: true,
        moduloColumns: {
            columnWidth: '.taxonomy-view-block-size',
            gutter: 0 // gutter: 20
        }
    // packery: {
        // gutter: 0,
        // columnWidth: '.taxonomy-view-block-size'
        // }
    });
});
