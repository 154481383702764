// term
$(document).ready(function() {
    if ($('.wpgmza_map').length > 0) {
        $('.wpgmza_gd').click(function(e) {
            e.preventDefault();
            console.log('click' + $(this));
            var w = window.open('http://maps.google.com/?q=' + $(this).attr('wpgm_addr_field'));
            w.focus();
        });
    }

    $('#wpgmaps_directions_edit_1').remove();
});
