$(document).ready(function() {
    // move load more button to another div: otherwise it bugs out on this app.
    var alm_is_animating = false;

    if ($('.alm-btn-wrap').length > 0) {
        $('.projects-overview-load-more').append($('.alm-btn-wrap'));
    }

    // Only returns one value for each key at the moment (22.10.2015)
    function getQueryString() {
        var queryString = {};
        var query = window.location.search.substring(1);
        var vars = query.split('&');

        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            // If first entry with this name
            if (typeof queryString[pair[0]] === 'undefined') {
                queryString[pair[0]] = decodeURIComponent(pair[1]);
                // If second entry with this name
            } else if (typeof queryString[pair[0]] === 'string') {
                var arr = [queryString[pair[0]], decodeURIComponent(pair[1])];
                queryString[pair[0]] = arr;
                // If third or later entry with this name
            } else {
                queryString[pair[0]].push(decodeURIComponent(pair[1]));
            }
        }

        return queryString;
    }

    var firstLoad = true;
    /*
    function filterProjects(data) {
        data.post_type = 'projects';
        data.scroll = false;
        data.posts_per_page = 8;
        data.images_loaded = true;
        data.button_label = 'more';
        data.transition = 'fade';

        $.fn.almFilter('fade', '300', data);
    }
    */

    // Project filter based  on category and taxonomies
    $.fn.almComplete = function() {
        if (firstLoad) {
            firstLoad = false;
        } else {
            if ($('#projects-overview').length > 0) {
                var data = {};
                var filterArr = getQueryString();

                $.each(filterArr, function(key) {
                    if (key === 'category' || key === 'c' || key === 'service') {
                        data.category = filterArr[key];
                    } else {
                        data.taxonomy = key;
                        data.taxonomy_terms = filterArr[key];
                    }
                });
                // filterProjects(data);
            }
        }


        if ($('.alm-btn-wrap').length > 0) {
            if ($('.alm-btn-wrap').length > 1) {
                $('.projects-overview-load-more').empty();
            }
            $('.projects-overview-load-more').append($('.alm-btn-wrap'));
        }
    };

    $.fn.almFilterComplete = function() {
        alm_is_animating = false; // clear alm_isanimating flag
    };
});
