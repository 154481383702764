$(document).ready(function() {
    if ($('.gallery').length > 0) {
        $('.gallery .imgcolumn').matchHeight({
            byRow: true,
            property: 'height',
            target: null,
            remove: false
        });

        $('a.gallery-image').nivoLightbox({
            effect: 'fade',
            theme: 'default',
            keyboardNav: true,
            clickOverlayToClose: true
        });
    }

    $(window).resize(function() {
        $.fn.matchHeight._update(); // update galleries if they exist.
    });

    $('.gallery .imgcolumn img').on('load', function() {
        $.fn.matchHeight._update(); // update galleries if they exist.
    });
});
