'use strict';
$(document).ready(function() {
    var autoplaySpeed = 6500;
    $('.slides_wrapper')
        .on('init', function() {
            $('.slide-progress').stop().css('width', '0vw');
            $('.slide-progress').animate({ width: '100vw' }, autoplaySpeed);
        })
        .slick({
            autoplay: true,
            autoplaySpeed: autoplaySpeed,
            dots: false,
            fade: true,
            swipeToSlide: true,
            arrows: true,
            speed: 1500,
            mobileFirst: true,
            pauseOnHover: false,
            pauseOnDotsHover: false,
            infinite: true,
            lazyLoad: 'ondemand'
        })
        .on('beforeChange', function() {
            $('.slide-progress').stop().css('width', '0vw');
        })
        .on('swipe', function() {
            $('.slide-progress').stop().css('width', '0vw');
        })
        .on('afterChange', function() {
            $('.slide-progress').animate({ width: '100vw' }, autoplaySpeed);
        });
    // $('.row.slides').show();
});
